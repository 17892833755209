/* stylelint-disable */
html,
body {
  height: 100%;
  margin: 0; /* Reset default margin on the body element */
  font-family: Oxanium, Roboto, sans-serif;
  font-weight: bold;
}

iframe {
  display: block; /* iframes are inline by default */
  background: #fff;
  border: none; /* Reset default border */
  width: 100%;
  height: calc(100vh - 158px);
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

header {
  height: 64px;
  background-color: #d71a21;
}

header span {
  color: #fff;
  font-size: 24px;
  padding: 17px 20px;
  display: block;
}

footer {
  background-color: #d71a21;
}

.privacy-policy {
  text-align: center;
  padding: 0 0 10px;
}

.privacy-policy a {
  color: #fff;
  font-size: 14px;
}

.footer-parties {
  text-align: center;
  padding: 4px 0;
}

.footer-parties p {
  margin: 0;
}

.footer-image {
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  margin: 8px 2px;
  background: #fff;
  padding: 4px 8px;
  border-radius: 4px;
}

@media (max-width: 500px) {
  
  footer {
    background-color: #d71a21;
  }

  .footer-parties {
    max-width: 320px;
    margin: auto;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.1s, opacity 0.3s ease;
}

.modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: #f3f3f3;
}

.modal-close {
  position: absolute;
  top: 8px;
  right: 30px;
  border: none;
  outline: none;
  background: none;
  font-size: 24px;
  color: #505050;
  font-weight: bold;
  cursor: pointer;
  @media (max-width: 500px) {
    top: 8px;
    right: 12px;
  }
}

.modal-close:hover {
  color: #262626;;
}

.modal-container {
  position: relative;
  z-index: 100;
  width: 100%;
  max-width: 100%;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;  
  color: #505050;
  background-color: #fff;
  .modal-scroll {
    height: 100%;
    overflow-y: scroll;
    padding: 24px;
    box-sizing: border-box;
    @media (max-width: 500px) {
      padding-right: 48px;
      word-break: break-word;
    }
    p {
      font-size: 14px;
      margin: 8px 0;
    }
    br {
      content: "\A";
      white-space: pre;
      height: 10px;
      display: block;
  
    }
    a {
      color: #d71a21;
      font-size: 14px;
    }
    h2 {
      font-size: 40px;
      margin: 20px 0;
    }
    h3 {
      font-size: 30px;
    }
    h4 {
      font-size: 20px;
      margin: 16px 0;
    }
    .modal-inner {
      max-width: 750px;
    }
  }
}

.hidden {
  display: none !important;
}